import React from "react";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [ smallParent, setSmallParent ] = React.useState(false);

  const { t } = useTranslation();

  const style = {
    borderRadius: 15,
    overflow: 'hidden',
  };

  React.useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 800px)');
    setSmallParent(mediaQuery.matches);

    const handler = (e: MediaQueryListEvent) => {
      setSmallParent(e.matches);
    };

    mediaQuery.addEventListener('change', handler);

    return () => {
      mediaQuery.removeEventListener('change', handler);
    }
  }, []);

  return (
    <div style={{
      justifyContent: 'center',
      textAlign: 'center',
      height: '100%',
      width: '100%',
      overflow: 'scroll',
      padding: smallParent ? '0px' : '20px',
    }}>
      { !smallParent && <img src={t("mock/imgs/dashboard_en.png")} style={{
        width: '100%',
        maxWidth: '700px',
        objectFit: 'contain',
        marginTop: '20px',
        marginBottom: '20px',
        borderRadius: '15px',
      }} /> }
      { smallParent && <img src={t("mock/imgs/dashboard_en_m.png")} style={{
        width: '100%',
        objectFit: 'contain',
        marginTop: '15px',
        marginBottom: '15px',
        borderRadius: '15px',
      }} /> }
    </div>
    // <Grid container spacing={2}>
    //   <Grid item xs={3}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    //   <Grid item xs={3}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    //   <Grid item xs={3}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    //   <Grid item xs={3}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    //   <Grid item xs={6}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    //   <Grid item xs={6}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    // </Grid>
  );
};

export default Dashboard;
