import { Box, Button, Card, CardActions, CardContent, Divider, Grid, Pagination, Stack, Typography } from "@mui/material";
import React from "react";
import { agri, gray, midGray } from "../theme";
import { useScreenSize } from "../common/hooks/useScreenSize";
import { Learnable, learnables } from "../classes/learnable";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface LearnPaginationProps {
  currentPage: number;
  pages: number;
  onPageChange: (page: number) => void;
}

const LearnPagination = (props: LearnPaginationProps) => {
  return (
    <Box style={{
      padding: '16px',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Pagination
        count={props.pages}
        page={props.currentPage}
        onChange={(e, page) => {
          props.onPageChange(page);
        }}
        color="primary" />
    </Box>
  );
};

interface LearnCardProps {
  learn: Learnable;
};

const LearnCard = (props: LearnCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Card style={{
      height: '100%',
      maxHeight: '500px',
      backgroundColor: gray,
      borderRadius: '16px',
      textAlign: 'center',
    }}>
      <Stack style={{
        height: '100%',
      }}>
        <CardContent style={{
          height: 'calc(100% - 60px)',
          position: 'relative',
        }}>
          <Stack spacing={2} style={{
            height: '100%',
          }}>
            <Typography variant="h5" component="div">
              { t(props.learn.name) }
            </Typography>
            <div style={{
              width: '100%',
              maxHeight: '40%',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}>
              <img src={props.learn.cover ?? "/mock/imgs/manual.webp"} style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '16px',
              }} />
            </div>
            <Divider style={{
              borderColor: 'rgba(255, 255, 255, 0.2)',
            }} />
            { props.learn.documents && <div style={{
              overflowY: 'auto',
            }}>
              <table style={{
                width: '100%',
              }}>
                <tbody>
                  { props.learn.documents.map((doc, index) => (
                    <tr key={doc.name}>
                      <td>
                        { t(doc.name) }
                      </td>
                      <td>
                        <Button variant="text" onClick={async () => {
                          navigate(`/pilot/learn/${props.learn.uuid}?documentIndex=${index}`);
                        }} disabled={!doc.path}>
                          {t('Visualize')}
                        </Button>
                      </td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </div> }
          </Stack>
        </CardContent>
        <CardActions style={{
          backgroundColor: (props.learn.documents && props.learn.documents.filter(doc => !!doc.path).length > 0) ? agri : midGray,
          height: '60px',
          padding: 0,
        }}>
          <React.Fragment>
            <Stack direction="row"
            spacing={4}
            style={{
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              textTransform: 'uppercase',
              color: 'white',
            }}>
              <Stack direction="row" spacing={1}>
                <VisibilityIcon fontSize="large" />
                <Typography style={{
                  cursor: 'pointer',
                }} variant="h6" onClick={async () => {
                  if (!props.learn.documents || props.learn.documents.filter(doc => !!doc.path).length === 0) {
                    return;
                  }
                  navigate(`/pilot/learn/${props.learn.uuid}`);
                }}>
                  {t('Visualize')}
                </Typography>
              </Stack>
            </Stack>
          </React.Fragment>
        </CardActions>
      </Stack>
    </Card>
  );
};

interface LearnPageProps {
  learns: Learnable[];
}

const LearnPage = (props: LearnPageProps) => {
  return (
    <Grid container spacing={2} style={{
      height: 'calc(100% - 64px)',
      padding: '32px',
      paddingBottom: 0,
    }}>
      { props.learns.map((learn) => (
        <Grid item xs={12} md={6} lg={4} xl={3} key={learn.uuid} style={{height: '100%'}}>
          <LearnCard
            learn={learn}
          />
        </Grid>
      )) }
    </Grid>
  );
};

const Learn = () => {
  const [ learns, setLearns ] = React.useState<Learnable[]>(learnables);
  const [ itemsPerPage, setItemsPerPage ] = React.useState(1);
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState(1);
  const [ currentPage, setCurrentPage ] = React.useState<Learnable[]>([]);

  const { medium, large, extraLarge } = useScreenSize();

  React.useEffect(() => {
    if (extraLarge) {
      setItemsPerPage(4);
    } else if (large) {
      setItemsPerPage(3);
    } else if (medium) {
      setItemsPerPage(2);
    } else {
      setItemsPerPage(1);
    }
  }, [medium, large, extraLarge]);

  React.useEffect(() => {
    const start = (currentPageNumber - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setCurrentPage(learns.slice(start, end));
  }, [learns, itemsPerPage, currentPageNumber]);

  return (
    <React.Fragment>
      <Stack style={{
        height: '100%',
      }}>
        <LearnPage
          learns={currentPage} />
        <LearnPagination
          onPageChange={(page) => setCurrentPageNumber(page)}
          currentPage={currentPageNumber}
          pages={Math.ceil(learns.length / itemsPerPage)} />
      </Stack>
    </React.Fragment>
  );
};

export default Learn;
